<template>
  <div class="">
    <v-navigation-drawer app class="ma-0 pa-0" v-model="mostrar">
      <opciones />
    </v-navigation-drawer>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="mostrar = !mostrar"></v-app-bar-nav-icon>
      <h3>REGISTRO DE NUEVO CASILLERO:</h3>
      <v-flex text-right>
        <v-btn color="success" @click="registrar()" class="ml-auto" small>
          Actualizar
        </v-btn>
      </v-flex>
    </v-app-bar>
    <v-container elevation="10" fluid>
      <v-tabs fixed-tabs v-model="tabs">
        <v-tab> SOLICITAR </v-tab>
        <v-tab> Costos </v-tab>
        <v-tab> Instructivo </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <v-card class="px-10 pt-5">
            <div class="col-12">
              <NuevoCasilleroView :type="'editar'" />
            </div>
            <div class="col-12">
              <ServicioCasilleroView :type="'editar'" />
            </div>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <CostosCasilleroView :type="'editar'" />
        </v-tab-item>
        <v-tab-item>
          <InstructivoCasilleroView :type="'editar'" />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions, mapMutations, mapState } from "vuex";
import funcion from "@/mixins/funciones";

// import { mapActions } from "vuex";
export default {
  name: "nuevoCasillero",
  mixins: [funcion],
  components: {
    opciones: () => import("@/components/comun/opcionesCasillero.vue"),
    NuevoCasilleroView: () =>
      import("@/components/casillero/nuevoCasilleroComponet.vue"),
    ServicioCasilleroView: () =>
      import("@/components/casillero/serviciosCasilleroComponent.vue"),
    CostosCasilleroView: () =>
      import("@/components/casillero/costosCasilleroComponent.vue"),
    InstructivoCasilleroView: () =>
      import("../../components/casillero/instructivoCasilleroComponent.vue"),
  },
  data() {
    return {
      mostrar: true,
      tabs: "",
      tabCostos: true,
      tabNotas: true,
      tabInstructivo: true,
      registrarFlag: true,
    };
  },
  computed: {
    ...mapState(["casillero", "costos", "multiplicador", "services"]),
  },
  methods: {
    ...mapMutations(["setCasillero"]),
    ...mapActions([
      "getLockerVer",
      "actualizarCasillero",
      "getInstructivoCasillero",
      "getItemsServices",
      "getItemsServicesDetails",
      "getListadoCasillero",
      "getModuleRole",
      "getMultiplicador",
      "getListadoQuoteCasillero",
      "getQuoteStatus",
    ]),
    costear() {
      if (
        !this.casillero.expediente ||
        !this.casillero.fechaSalida ||
        this.casillero.listQuote.length == 0
      ) {
        Swal.fire({
          icon: "error",
          title: "ADVERTENCIA",
          text: "El N° Expediente, la Fecha Salida y la lista de Cotizaciones son requeridas",
        });
      } else {
        Swal.fire({
          icon: "question",
          title: "¿Desea Pasar a Costeo?",
          showDenyButton: true,
          confirmButtonText: "Si",
          denyButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            this.tabs = 1;
            this.tabCostos = false;
            this.registrarFlag = false;
          }
          if (result.isDenied) {
            this.registrar(false);
          }
        });
      }
    },
    async registrar() {
      let res = {
        volumen: 0,
        peso: 0,
        ganancia: 0,
      };

      this.casillero.listQuote
        .filter((v) => v.select)
        .forEach((element) => {
          res.volumen += parseFloat(element.volumen);
          res.peso += parseFloat(element.peso);
          res.nroBultos += parseFloat(element.nroBultos);
          res.ganancia += element.ganancia ? parseFloat(element.ganancia) : 0;
        });

      this.casillero.volumen = res.volumen;
      this.casillero.peso = res.peso;
      this.casillero.ganancia = res.ganancia;
      let serv = [];
      this.services.forEach((element) => {
        serv.push({
          id: element.id ? element.id : "",
          idBegEnd: element.id_begend,
          nameservice: element.namegroupservice,
          statusService: element.status,
          id_groupservices: element.id_groupservices
            ? element.id_groupservices
            : "",
          codegroupservices: element.codegroupservices,
          codeItemservices: element.codeItemservices,
          id_begend: element.id_begend,
        });
      });
      let cost = [];

      this.costos.forEach((element) => {
        let codemultiplicador = element.id_multiplicador
          ? this.multiplicador.filter(
              (v) => v.id == element.id_multiplicador
            )[0]
          : [];
        let subTotal = 0;
        if (codemultiplicador) {
          if (
            codemultiplicador.code != 5 &&
            codemultiplicador.code != 13 &&
            codemultiplicador.code != 14
          ) {
            subTotal =
              codemultiplicador.valor *
              element.costounitario *
              this.calcularFac(
                codemultiplicador.code,
                this.casillero.volumen,
                this.casillero.peso,
                null,
                this.casillero.ganancia
              );
          } else if (
            codemultiplicador.code == 5 ||
            codemultiplicador.code == 13 ||
            codemultiplicador.code == 14
          ) {
            this.calcularValor(
              this.casillero.ganancia,
              this.totalDeFlete,
              codemultiplicador.code,
              codemultiplicador.code == 14 ? element.cif : element.seguro
            );
          }
        } else {
          subTotal = 0;
        }
        if (
          !(
            codemultiplicador.code == 5 &&
            codemultiplicador.code == 13 &&
            codemultiplicador.code == 14 &&
            element.esorigenflag
          )
        ) {
          cost.push({
            id: element.id ? element.id : "",
            id_proveedor: element.id_proveedor,
            id_multiplicador: element.id_multiplicador,
            concepto: element.nameservice,
            costounitario: element.costounitario,
            minimo: element.minimo ? element.minimo : 0,
            esorigenflag: element.esorigenflag,
            eslocalflag: element.eslocalflag,
            esaduanaflag: element.esaduanaflag,
            esalmacenflag: element.esalmacenflag,
            esopcionflag: element.esventaflag == 1 ? 0 : 0,
            status: element.status,
            esventaflag: element.esventaflag,
            cif: element.cif,
            seguro: element.seguro,
            subtotal: subTotal,
          });
        }
      });

      this.casillero.costos = cost;
      this.casillero.servicios = serv;
      this.casillero.id = this.$route.params.id;

      await this.actualizarCasillero(this.casillero);
      this.$router.push({
        name: "ver-casillero",
        params: {
          id: this.$route.params.id,
        },
      });
      // console.log(res);
      // if (instructivo) {
      //   await this.getInstructivoCasillero(res);
      //   setTimeout(() => {
      //     this.tabs = 2;
      //     this.tabInstructivo = false;
      //     this.registrarFlag = true;
      //   }, 2000);
      // }

      //
    },
  },
  mounted() {
    this.getQuoteStatus();
    this.getLockerVer(this.$route.params.id);
    this.getListadoQuoteCasillero();
    this.getModuleRole();
    // this.getMultiplicador(3);
    // this.getItemsServices({
    //   id_modality: 1,
    //   id_shipment: 3,
    //   id_incoterms: 9,
    // });
  },
};
</script>

<style></style>
